//
// Copyright 2024 DXOS.org
//

import { Reference } from '@dxos/echo-protocol';
import {
  type BaseObject,
  getObjectAnnotation,
  type HasId,
  EchoSchema,
  type ObjectMeta,
  type S,
  SchemaValidator,
  requireTypeReference,
  Ref,
  EntityKind,
  getEntityKind,
  RelationSourceId,
  RelationTargetId,
} from '@dxos/echo-schema';
import { assertArgument, invariant } from '@dxos/invariant';
import { getRefSavedTarget, type ReactiveObject } from '@dxos/live-object';
import {
  createProxy,
  getMeta,
  getProxyHandler,
  getProxySlot,
  getProxyTarget,
  getSchema,
  isReactiveObject,
} from '@dxos/live-object';
import { deepMapValues } from '@dxos/util';

import { DATA_NAMESPACE, EchoReactiveHandler, isRootDataObject, PROPERTY_ID, throwIfCustomClass } from './echo-handler';
import { ObjectInternals, type ProxyTarget, symbolInternals, symbolNamespace, symbolPath } from './echo-proxy-target';
import { type DecodedAutomergePrimaryValue, ObjectCore } from '../core-db';
import { type EchoDatabase } from '../proxy-db';

// TODO(burdon): Rename EchoObject and reconcile with proto name.
export type ReactiveEchoObject<T extends BaseObject> = ReactiveObject<T> & HasId;

/**
 * @returns True if `value` is a reactive object with an EchoHandler backend.
 */
// TODO(dmaretskyi): Reconcile with `isTypedObjectProxy`.
export const isEchoObject = (value: any): value is ReactiveEchoObject<any> => {
  if (!isReactiveObject(value)) {
    return false;
  }

  const handler = getProxyHandler(value);
  if (!(handler instanceof EchoReactiveHandler)) {
    return false;
  }

  return isRootDataObject(getProxyTarget(value));
};

/**
 * Used to determine if the value should be placed at the root of a separate ECHO object.
 *
 * @returns True if `value` is a reactive object with an EchoHandler backend or a schema that has an `Object` annotation.
 */
// TODO(dmaretskyi): Reconcile with `isEchoObject`.
export const isTypedObjectProxy = (value: any): value is ReactiveObject<any> => {
  if (isEchoObject(value)) {
    return true;
  }

  const schema = getSchema(value);
  if (schema != null) {
    return !!getObjectAnnotation(schema);
  }

  return false;
};

/**
 * Creates a reactive ECHO object backed by a CRDT.
 * @internal
 */
// TODO(burdon): Document lifecycle.
export const createObject = <T extends BaseObject>(obj: T): ReactiveEchoObject<T> => {
  assertArgument(!isEchoObject(obj), 'Object is already an ECHO object');
  const schema = getSchema(obj);
  if (schema != null) {
    validateSchema(schema);
  }
  validateInitialProps(obj);

  const core = new ObjectCore();
  if (isReactiveObject(obj)) {
    // Already an echo-schema reactive object.
    const meta = getProxyTarget<ObjectMeta>(getMeta(obj));

    // TODO(burdon): Requires comment.
    const slot = getProxySlot(obj);
    slot.setHandler(EchoReactiveHandler.instance);

    const target = slot.target as ProxyTarget;
    target[symbolInternals] = new ObjectInternals(core);
    target[symbolInternals].rootSchema = schema;
    target[symbolPath] = [];
    target[symbolNamespace] = DATA_NAMESPACE;
    slot.handler._proxyMap.set(target, obj);

    target[symbolInternals].subscriptions.push(core.updates.on(() => target[symbolInternals].signal.notifyWrite()));

    // NOTE: This call is recursively linking all nested objects
    //  which can cause recursive loops of `createObject` if `EchoReactiveHandler` is not set prior to this call.
    //  Do not change order.
    initCore(core, target);
    slot.handler.init(target);

    setSchemaPropertiesOnObjectCore(target[symbolInternals], schema);
    setRelationSourceAndTarget(target, core, schema);

    if (meta && meta.keys.length > 0) {
      target[symbolInternals].core.setMeta(meta);
    }

    return obj as any;
  } else {
    const target: ProxyTarget = {
      [symbolInternals]: new ObjectInternals(core),
      [symbolPath]: [],
      [symbolNamespace]: DATA_NAMESPACE,
      ...(obj as any),
    };
    target[symbolInternals].rootSchema = schema;

    target[symbolInternals].subscriptions.push(core.updates.on(() => target[symbolInternals].signal.notifyWrite()));

    initCore(core, target);
    const proxy = createProxy<ProxyTarget>(target, EchoReactiveHandler.instance) as any;
    setSchemaPropertiesOnObjectCore(target[symbolInternals], schema);
    setRelationSourceAndTarget(target, core, schema);

    return proxy;
  }
};

// TODO(burdon): Call and remove subscriptions.
export const destroyObject = <T extends BaseObject>(proxy: ReactiveEchoObject<T>) => {
  invariant(isEchoObject(proxy));
  const target: ProxyTarget = getProxyTarget(proxy);
  const internals: ObjectInternals = target[symbolInternals];
  for (const unsubscribe of internals.subscriptions) {
    unsubscribe();
  }
};

const initCore = (core: ObjectCore, target: ProxyTarget) => {
  // Handle ID pre-generated by `create`.
  if (PROPERTY_ID in target) {
    target[symbolInternals].core.id = target[PROPERTY_ID];
    delete target[PROPERTY_ID];
  }

  core.initNewObject(linkAllNestedProperties(target));
};

/**
 * @internal
 */
export const initEchoReactiveObjectRootProxy = (core: ObjectCore, database?: EchoDatabase): ReactiveEchoObject<any> => {
  const target: ProxyTarget = {
    [symbolInternals]: new ObjectInternals(core, database),
    [symbolPath]: [],
    [symbolNamespace]: DATA_NAMESPACE,
  };

  // TODO(dmaretskyi): Does this need to be disposed?
  core.updates.on(() => target[symbolInternals].signal.notifyWrite());

  return createProxy<ProxyTarget>(target, EchoReactiveHandler.instance) as any;
};

const validateSchema = (schema: S.Schema.AnyNoContext) => {
  requireTypeReference(schema);
  const entityKind = getEntityKind(schema);
  invariant(entityKind === 'object' || entityKind === 'relation');
  SchemaValidator.validateSchema(schema);
};

const setSchemaPropertiesOnObjectCore = (internals: ObjectInternals, schema: S.Schema.AnyNoContext | undefined) => {
  if (schema != null) {
    internals.core.setType(requireTypeReference(schema));

    const kind = getEntityKind(schema);
    invariant(kind);
    internals.core.setKind(kind);
  }
};

const setRelationSourceAndTarget = (
  target: ProxyTarget,
  core: ObjectCore,
  schema: S.Schema.AnyNoContext | undefined,
) => {
  const kind = schema && getEntityKind(schema);
  if (kind === EntityKind.Relation) {
    // `getSource` and `getTarget` don't work here since they assert entity kind.
    const sourceRef = (target as any)[RelationSourceId];
    const targetRef = (target as any)[RelationTargetId];
    if (!sourceRef || !targetRef) {
      throw new TypeError('Relation source and target must be specified');
    }
    if (!isReactiveObject(sourceRef)) {
      throw new TypeError('source must be an ECHO object');
    }
    if (!isReactiveObject(targetRef)) {
      throw new TypeError('target must be an ECHO object');
    }

    core.setSource(EchoReactiveHandler.instance.createRef(target, sourceRef));
    core.setTarget(EchoReactiveHandler.instance.createRef(target, targetRef));
  }
};

const validateInitialProps = (target: any, seen: Set<object> = new Set()) => {
  if (seen.has(target)) {
    return;
  }

  seen.add(target);
  for (const key in target) {
    const value = target[key];
    if (value === undefined) {
      delete target[key];
    } else if (typeof value === 'object') {
      if (Ref.isRef(value)) {
        // Pass refs as is.
      } else if (value instanceof EchoSchema || isTypedObjectProxy(value)) {
        throw new Error('Object references must be wrapped with `makeRef`');
      } else {
        throwIfCustomClass(key, value);
        validateInitialProps(target[key], seen);
      }
    }
  }
};

const linkAllNestedProperties = (target: ProxyTarget): DecodedAutomergePrimaryValue => {
  return deepMapValues(target, (value, recurse) => {
    if (Ref.isRef(value)) {
      return refToEchoReference(target, value);
    }

    return recurse(value);
  });
};

const refToEchoReference = (target: ProxyTarget, ref: Ref<any>): Reference => {
  const savedTarget = getRefSavedTarget(ref);
  if (savedTarget) {
    return EchoReactiveHandler.instance.createRef(target, savedTarget);
  } else {
    return Reference.fromDXN(ref.dxn);
  }
};
